let References = [
    'Bocklandt S, Lin W, Sehl ME, Sánchez FJ, Sinsheimer JS, Horvath S, Vilain E. Epigenetic <br />predictor of age. PLoS One. 2011;6(6):e14821.',
    'Horvath, S.et al.Accelerated epigenetic aging in Down syndrome.Aging Cell 14, 491–495<br />(2015).',
    `Horvath, S.et al.An epigenetic clock analysis of race / ethnicity, sex, and coronary heart<br />    disease.Genome Biol 17, 171(2016).`,
    `Horvath et al. - 2016 - An epigenetic clock analysis of raceethnicity, se.pdf.`,
    `Hannum, G.et al.Genome - wide Methylation Profiles Reveal Quantitative Views of Human<br />    Aging Rates.Molecular Cell 49, 359–367(2013).`
    ,
    `Horvath, S.et al.Huntington’s disease accelerates epigenetic aging of human brain and<br />    disrupts DNA methylation levels.Aging 8, 1485–1512(2016).`,
    `Davis, E.G.et al.Accelerated DNA methylation age in adolescent girls: associations with elevated diurnal cortisol and reduced hippocampal volume.Transl Psychiatry 7, e1223–e1223(2017).`,
    `Dozmorov, M.G., Coit, P., Maksimowicz - McKinnon, K. & Sawalha, A.H.Age - associated DNA methylation changes in naive CD4 + T cells suggest an evolving autoimmune epigenotype in aging T cells.Epigenomics 9, 429–445(2017).`,
    `Solovev, I., Shaposhnikov, M. & Moskalev, A.Multi - omics approaches to human biological<br />age estimation.Mechanisms of Ageing and Development 185, 111192(2020).`,
    `Salameh, Y., Bejaoui, Y. & El Hajj, N.DNA Methylation Biomarkers in Aging and Age -<br />Related Diseases.Front.Genet. 11, 171(2020).`,
    `Horvath, S.et al.Obesity accelerates epigenetic aging of human liver.Proceedings of the<br />National Academy of Sciences 111, 15538–15543(2014).`
    , `Horvath, S.et al.Decreased epigenetic age of PBMCs from Italian semi - supercentenarians<br />and their offspring.Aging 7, 1159–1170(2015).`
    ,
    `Hayano, M.et al.DNA Break - Induced Epigenetic Drift as a Cause of Mammalian Aging.<br />http://biorxiv.org/lookup/doi/10.1101/808659 (2019) doi:10.1101/808659.`,
    `Dick, K.J.et al.DNA methylation and body - mass index: a genome - wide analysis.The<br />Lancet 383, 1990–1998(2014).`,
    `Geurts, Y.M.et al.Novel associations between blood DNA methylation and body mass<br />index in middle - aged and older adults.Int J Obes 42, 887–896(2018).`,
    `Wahl, S.et al.Epigenome - wide association study of body mass index, and the adverse<br />outcomes of adiposity.Nature 541, 81–86(2017).`
    ,
    `Sun, D.et al.Body Mass Index Drives Changes in DNA Methylation: A Longitudinal<br />Study.Circ Res 125, 824–833(2019).`,
    `Tam, B.T., Morais, J.A. & Santosa, S.Obesity and ageing: Two sides of the same coin.<br />Obesity Reviews 21, (2020).`,
    `Walaszczyk, E.et al.DNA methylation markers associated with type 2 diabetes, fasting glucose and HbA1c levels: a systematic review and replication in a case–control sample of the Lifelines study.Diabetologia 61, 354–368(2018).`,
    `Ronn, T.et al.Impact of age, BMI and HbA1c levels on the genome - wide DNA methylation and mRNA expression patterns in human adipose tissue and identification of epigenetic biomarkers in blood.Human Molecular Genetics ddv124(2015) doi: 10. 1093 / hmg / ddv124.`,
    `Willmer, T. , Johnson, R. , Louw, J. & Pheiffer, C.Blood - Based DNA Methylation Biomarkers for Type 2 Diabetes: Potential for Clinical Applications.Front.Endocrinol. 9, 744(2018).`,
    `Ling, C. & Rönn, T.Epigenetics in Human Obesity and Type 2 Diabetes.Cell Metabolism<br />29, 1028–1044(2019).`,
    `Cardona, A.et al.Epigenome - Wide Association Study of Incident Type 2 Diabetes in a<br>
British Population: EPIC - Norfolk Study.Diabetes 68, 2315–2326(2019).`,
    `Juvinao - Quintero, D.L.et al.DNA methylation of blood cells is associated with prevalent<br />type 2 diabetes in a meta - analysis of four European cohorts.Clin Epigenet 13, 40(2021).`,
    `Ma, J.et al.A Peripheral Blood DNA Methylation Signature of Hepatic Fat Reveals a Potential Causal Pathway for Nonalcoholic Fatty Liver Disease.Diabetes 68, 1073–1083(2019).`,
    `Chambers, J.C.et al.Epigenome - wide association of DNA methylation markers in peripheral blood from Indian Asians and Europeans with incident type 2 diabetes: a nested case - control study.The Lancet Diabetes & Endocrinology 3, 526–534(2015).`,
    `Ahmed, S.A.H., Ansari, S.A., Mensah - Brown, E.P.K. & Emerald, B.S.The role of DNA methylation in the pathogenesis of type 2 diabetes mellitus.Clinical Epigenetics 12, 104(2020).`
    ,
    `Jhun, M.- A.et al.A multi - ethnic epigenome - wide association study of leukocyte DNA<br />methylation and blood lipids.Nat Commun 12, 3987(2021).`,
    `Muka, T.et al.The role of global and regional DNA methylation and histone modifications in glycemic traits and type 2 diabetes: A systematic review.Nutrition, Metabolism and Cardiovascular Diseases 26, 553–566(2016).`,
    `Wilson, L.E., Harlid, S., Xu, Z., Sandler, D.P. & Taylor, J.A.An epigenome - wide study of body mass index and DNA methylation in blood using participants from the Sister Study cohort.Int J Obes 41, 194–199(2017).`,
    `Mendelson, M.M.et al.Association of Body Mass Index with DNA Methylation and Gene Expression in Blood Cells and Relations to Cardiometabolic Disease: A Mendelian Randomization Approach.PLoS Med 14, e1002215(2017).`,
    `Aslibekyan, S.et al.Epigenome - wide study identifies novel methylation loci associated with body mass index and waist circumference: Epigenetics of Obesity.Obesity 23, 1493–1501(2015).`,
    `Rohde, K.et al.Genetics and epigenetics in obesity.Metabolism 92, 37–50(2019).`,
    `Campanella, G.et al.Epigenome - wide association study of adiposity and future risk of<br />obesity - related diseases.Int J Obes 42, 2022–2035(2018).`
    , `Lupo, P.J.et al.DNA methylation and obesity in survivors of pediatric acute lymphoblastic<br />leukemia(ALL): A report from the Childhood Cancer Survivor Study(CCSS). 1.`
    ,
    `Kriebel, J.et al.Association between DNA Methylation in Whole Blood and Measures of<br />Glucose Metabolism: KORA F4 Study.PLoS ONE 11, e0152314(2016).`
    , `Arpón, A.et al.Epigenome - wide association study in peripheral white blood cells<br />involving insulin resistance.Sci Rep 9, 2445(2019).`
    ,
    `	Mittelstraß, K. & Waldenberger, M.DNA methylation in human lipid metabolism and<br />related diseases: Current Opinion in Lipidology 29, 116–124(2018).`,
    `Pfeiffer, L.et al.DNA Methylation of Lipid - Related Genes Affects Blood Lipid Levels.<br />Circulation.Cardiovascular genetics 8, 334–342(2015).`
    , `Hedman, Å.K.et al.Epigenetic Patterns in Blood Associated With Lipid Traits Predict Incident Coronary Heart Disease Events and Are Enriched for Results From Genome - Wide<br />Association Studies.Circ Cardiovasc Genet 10, (2017).`,
    `Gomez - Alonso, M.del C.et al.DNA methylation and lipid metabolism: an EWAS of 226<br />metabolic measures.Clin Epigenet 13, 7(2021).`
    , `Irvin, M.R.et al.Epigenome - Wide Association Study of Fasting Blood Lipids in the<br />Genetics of Lipid Lowering Drugs and Diet Network Study.Circulation 130, 565–572(2014).`
    , `Tobi, E.W.et al.DNA methylation as a mediator of the association between prenatal<br />adversity and risk factors for metabolic disease in adulthood.Sci.Adv. 4, eaao4364(2018).`,
    `Akinyemiju, T.et al.Epigenome - wide association study of metabolic syndrome in African -<br />American adults.Clin Epigenet 10, 49(2018).`,
    `Kho, M.et al.Epigenetic loci for blood pressure are associated with hypertensive target organ damage in older African Americans from the genetic epidemiology network of Arteriopathy(GENOA) study.BMC Med Genomics 13, 131(2020).`,
    `Biomarkers of Human Aging.vol. 10(Springer International Publishing, 2019).`,
    `Irvin, M.R.et al.Metabolic and inflammatory biomarkers are associated with epigenetic<br />aging acceleration estimates in the GOLDN study.Clin Epigenet 10, 56(2018).`,
    `Sayols - Baixeras, S.et al.Identification and validation of seven new loci showing differential DNA methylation related to serum lipid profile: an epigenome - wide approach.The REGICOR study.Human Molecular Genetics 25, 4556–4565(2016).`
    ,
    `Jones, A.C., Irvin, M.R., Claas, S.A. & Arnett, D.K.Lipid Phenotypes and DNA<br />Methylation: a Review of the Literature.Curr Atheroscler Rep 23, 71(2021).`,
    `Dayeh, T.et al.DNA methylation of loci within ABCG1 and PHOSPHO1 in blood DNA is<br />associated with future type 2 diabetes risk.Epigenetics 11, 482–488(2016).`
    ,
    `Krause, C.et al.Critical evaluation of the DNA - methylation markers ABCG1 and SREBF1<br />for Type 2 diabetes stratification.Epigenomics 11, 885–897(2019).`,
    `Qie, R.et al.Association of ABCG1 gene methylation and its dynamic change status with incident type 2 diabetes mellitus: the Rural Chinese Cohort Study.J Hum Genet 66, 347–357(2021).`,
    `Richard, M.A.et al.DNA Methylation Analysis Identifies Loci for Blood Pressure<br />Regulation.The American Journal of Human Genetics 101, 888–902(2017).`,
    `Irvin, M.R., Jones, A.C., Claas, S.A. & Arnett, D.K.DNA Methylation and Blood Pressure Phenotypes: A Review of the Literature.American Journal of Hypertension 34, 267– 273(2021).`
    , `Das, M.et al.Association of DNA Methylation at CPT1A Locus with Metabolic Syndrome in the Genetics of Lipid Lowering Drugs and Diet Network(GOLDN) Study.PLoS ONE 11, e0145789(2016).`
    , `Rathore, R., Schutt, C.R. & Van Tine, B.A.PHGDH as a mechanism for resistance in<br />metabolically - driven cancers.CDR(2020) doi: 10.20517 / cdr.2020.46.`,
    `Mizuno, G.et al.Association between the extent of DNA methylation at the CpG sites of HIF3A and parameters of obesity in the general Japanese population.http://biorxiv.org/lookup/ doi/10.1101/2020.11.30.403709 (2020) doi:10.1101/2020.11.30.403709.`,
    `Huang, T.et al.DNA Methylation Variants at HIF3A Locus, B - Vitamin Intake, and Long - term Weight Change: Gene - Diet Interactions in Two U.S.Cohorts.Diabetes 64, 3146–3154(2015).`
    , `Main, A.M.et al.DNA methylation and gene expression of HIF3A: cross - tissue validation and associations with BMI and insulin resistance.Clinical Epigenetics 8, 89(2016).`
    , `Shen, J.et al.HIF3A DNA methylation, obesity and weight gain, and breast cancer risk among Mexican American women.Obesity Research & Clinical Practice 14, 548–553(2020).`,
    `Wang, Y.et al.High Glucose Induces Lipid Accumulation via 25 - Hydroxycholesterol<br />DNA - CpG Methylation.iScience 23, 101102(2020).`,
    `Ali, O.et al.Methylation of SOCS3 is inversely associated with metabolic syndrome in an<br />epigenome - wide association study of obesity.Epigenetics 11, 699–707(2016).`,
    `Wang, L.et al.Combined effects of progesterone and SOCS3 DNA methylation on<br />T2DM: a case–control study.Clin Epigenet 13, 181(2021).`
    , `K., Zhang, X., Wang, Z., Hu, Y. & Sinha, R.Epigenome - wide association analysis revealed that SOCS3 methylation influences the effect of cumulative stress on obesity.Biological Psychology 131, 63–71(2018).`,
    `Gao, Xu et al.Comparative validation of an epigenetic mortality risk score with three aging biomarkers for predicting mortality risks among older adult males.International Journal of Epidemiology. 48, 6, 1958 - 1971.(2019).`,
    `Zaghlool, S.B.et al.Deep molecular phenotypes link complex disorders and physiological<br />insult to CpG methylation.Human Molecular Genetics 27, 1106–1121(2018).`,
    `Kankaanpää, A.et al.Leisure - Time and Occupational Physical Activity Associates Differently with Epigenetic Aging.Medicine & Science in Sports & Exercise 53, 487–495(2021).`
    , `Zannas, A.S.et al.Lifetime stress accelerates epigenetic aging in an urban, African<br />American cohort: relevance of glucocorticoid signaling.Genome Biol 16, 266(2015).`
    , `Hughes, A.et al.Socioeconomic Position and DNA Methylation Age Acceleration Across<br />the Life Course.American Journal of Epidemiology 187, 2346–2354(2018).`,
    `White, A.J., Kresovich, J.K., Xu, Z., Sandler, D.P. & Taylor, J.A.Shift work, DNA methylation and epigenetic age.International Journal of Epidemiology 48, 1536–1544(2019).`,
    `Ozbay, F.et al.social support and resilience to stress: from neurobiology to clinical<br />practice.Psychiatry(Edgmont) 4, 35–40(2007).`,
    `Ma, X.et al.The Effect of Diaphragmatic Breathing on Attention, Negative Affect and<br />Stress in Healthy Adults.Front.Psychol. 8, 874(2017).`,
    `Li, J.et al.Exposure to Polycyclic Aromatic Hydrocarbons and Accelerated DNA<br />Methylation Aging.Environ Health Perspect 126, 067005(2018).`,
    `van der Laan, L.et al.Epigenetic aging biomarkers and occupational exposure to benzene,<br />trichloroethylene and formaldehyde.Environment International 158, 106871(2022).`,
    `Rider, C.F. & Carlsten, C.Air pollution and DNA methylation: effects of exposure in<br />humans.Clinical Epigenetics 11, 131(2019).`,
    `Rebelo - Marques, A.et al.Aging Hallmarks: The Benefits of Physical Exercise.Front.<br />Endocrinol. 9, 258(2018).`,
    `Spólnicka, M.et al.Modified aging of elite athletes revealed by analysis of epigenetic age<br />markers.Aging 10, 241–252(2018).`,
    `Rosen, A.D.et al.DNA methylation age is accelerated in alcohol dependence.Transl<br />Psychiatry 8, 182(2018).`,
    `Karabegović, I.et al.Epigenome - wide association meta - analysis of DNA methylation<br />with coffee and tea consumption.Nat Commun 12, 2830(2021).`,
    `Gallardo - Escribano, C.et al.Epigenetic approach in obesity: DNA methylation in a prepubertal population which underwent a lifestyle modification.Clin Epigenet 12, 144(2020).`,
    `Jacka, F.N.et al.A randomised controlled trial of dietary improvement for adults with<br />major depression(the ‘SMILES’ trial).BMC Med 15, 23(2017).`
    , `American Diabetes Association. 5. Facilitating Behavior Change and Well - being to Improve Health Outcomes: Standards of Medical Care in Diabetes—2020. Diabetes Care 43, S48–S65(2020).`
    , `Quach, A.et al.Epigenetic clock analysis of diet, exercise, education, and lifestyle factors.<br />Aging 9, 419–446(2017).`,
    `Lillycrop, K.A., Hoile, S.P., Grenfell, L. & Burdge, G.C.DNA methylation, ageing and<br />the influence of early life nutrition.Proc.Nutr.Soc. 73, 413–421(2014).`,
    `Nakagawa, S., Lagisz, M., Hector, K.L. & Spencer, H.G.Comparative and meta - analytic insights into life extension via dietary restriction: Dietary restriction and longevity: meta - analysis.Aging Cell 11, 401–409(2012).`
    , `Colman, R.J.et al.Caloric restriction delays disease onset and mortality in rhesus<br />monkeys.Science(New York, N.Y.) 325, 201–204(2009).`,
    `Hahn, O.et al.Dietary restriction protects from age - associated DNA methylation and<br />induces epigenetic reprogramming of lipid metabolism.Genome Biol 18, 56(2017).`,
    `Simons, M.J.P., Koch, W. & Verhulst, S.Dietary restriction of rodents decreases aging<br />rate without affecting initial mortality rate –a meta - analysis.Aging Cell 12, 410–414(2013).`,
    `Yaskolka Meir, A.et al.Lifestyle weight - loss intervention may attenuate methylation<br />aging: the CENTRAL MRI randomized controlled trial.Clin Epigenet 13, 48(2021).`,
    `Petersen, C.L. , Christensen, B.C. & Batsis, J.A.Weight management intervention identifies association of decreased DNA methylation age with improved functional age measures in older adults with obesity.Clin Epigenet 13, 46(2021).`,
    `Ma, J.et al.Whole Blood DNA Methylation Signatures of Diet Are Associated With Cardiovascular Disease Risk Factors and All - Cause Mortality.Circ: Genomic and Precision Medicine 13, (2020).`,
    `Sofi, F. , Abbate, R. , Gensini, G.F. & Casini, A.Accruing evidence on benefits of adherence to the Mediterranean diet on health: an updated systematic review and meta - analysis.The American Journal of Clinical Nutrition 92, 1189–1196(2010).`,
    'Belsky, Daniel W et al. “Change in the Rate of Biological Aging in Response to Caloric Restriction: CALERIE Biobank Analysis.” The journals of gerontology.Series A, Biological sciences and medical sciences vol. 73, 1(2017): 4 - 10. doi: 10.1093 / gerona / glx096',
    `Liu, C.- C., Liu, C.- C., Kanekiyo, T., Xu, H. & Bu, G.Apolipoprotein E and Alzheimer<br />disease: risk, mechanisms and therapy.Nature Reviews.Neurology 9, 106–118(2013).`,
    `Walker, R.M.et al.Identification of epigenome - wide DNA methylation differences<br />between carriers of APOE ε4 and APOE ε2 alleles.Genome Med 13, 1(2021).`,
    `Van der Put, N.M.et al.A second common mutation in the methylenetetrahydrofolate reductase gene: an additional risk factor for neural - tube defects ? American Journal of Human Genetics 62, 1044–1051(1998).`,
    `Dean, L.Methylenetetrahydrofolate Reductase Deficiency.in Medical Genetics Summaries(eds.Pratt, V.M.et al.)(National Center for Biotechnology Information(US), 2012).`,
    `CDC.MTHFR Gene and Folic Acid.Centers for Disease Control and Prevention(2020).`
];
export {
    References
}